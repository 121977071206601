<template>
  <ValidationObserver tag="div" v-slot="{ handleSubmit, invalid }">
    <sidebar />

    <form @submit.prevent="handleSubmit(onSubmit)" class="survey-form">
      <header class="header">
        <icon-button
          icon="bars"
          label="Abrir"
          @click="openSidebar"
          v-show="!isSidebarOpen"
        />

        <logo size="sm" />

        <div class="btn-container">
          <save-progress-button />
          <logout-button />
        </div>
      </header>

      <transition name="question" mode="out-in">
        <template v-for="(question, index) in surveyForm">
          <div v-if="currentStep === index" :key="index" class="question-container">
            <scale
              v-if="question.section.id === 'enps'"
              :label="question.question.text"
              :picked="question.answer"
              rules="required"
              @change="setAnswer({ index, answer: $event })"
            />
            <radio-button-group
              v-else-if="typeof question.answer === 'object'"
              :label="question.name || question.question.text"
              :groupName="question.name || question.question.text"
              :options="question.options || scale"
              rules="required"
              :picked="question.answer"
              @change="setAnswer({ index, answer: $event })"
            />
            <custom-textarea
              v-else
              :label="`${question.question.text} (Opcional)`"
              :value="question.answer"
              placeholder="Escriba aquí"
              @input="setAnswer({ index, answer: $event })"
            />
          </div>
        </template>
      </transition>

      <div class="navigation">
        <custom-button type="button" variant="secondary" @click="previous">
          <font-awesome-icon icon="long-arrow-alt-left" slot="prepend-icon" />
          Anterior
        </custom-button>

        <progress-bar :total="formLength" :progress="currentStep + 1" />

        <custom-button type="submit" variant="primary" :disabled="invalid">
          <template v-if="currentStep < formLength - 1">
            <font-awesome-icon icon="long-arrow-alt-right" slot="append-icon" />
            Siguiente
          </template>

          <template v-else>
            <font-awesome-icon icon="paper-plane" slot="append-icon" />
            Enviar
          </template>
        </custom-button>
      </div>

      <div class="contact-us">
        <span>¿Necesitas ayuda?</span>
        <a
          href="https://api.whatsapp.com/send?phone=51984126441"
          target="_blank"
          rel="noopener noreferrer"
          class="link"
        >
          Escríbenos a WhatsApp
        </a>
      </div>
    </form>

    <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>

    <loading-mask :loading="loading" />
  </ValidationObserver>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import { ValidationObserver } from 'vee-validate';

import CustomButton from '../components/CustomButton.vue';
import ProgressBar from '../components/ProgressBar.vue';
import RadioButtonGroup from '../components/RadioButtonGroup.vue';
import SaveProgressButton from '../components/SaveProgressButton.vue';
import LogoutButton from '../components/LogoutButton.vue';
import ConfirmDialogue from '../components/ConfirmDialogue.vue';
import Sidebar from '../components/Sidebar.vue';
import LoadingMask from '../components/LoadingMask.vue';
import Logo from '../components/Logo.vue';
import CustomTextarea from '../components/CustomTextarea.vue';
import Scale from '../components/Scale.vue';
import IconButton from '../components/IconButton.vue';

export default {
  components: {
    ValidationObserver,
    CustomButton,
    ProgressBar,
    RadioButtonGroup,
    SaveProgressButton,
    LogoutButton,
    ConfirmDialogue,
    Sidebar,
    LoadingMask,
    Logo,
    CustomTextarea,
    Scale,
    IconButton,
  },

  data: () => ({
    loading: false,
  }),

  computed: {
    ...mapState([
      'userProfile',
      'company',
      'survey',
      'surveyForm',
      'currentStep',
      'formLength',
      'isSidebarOpen',
      'scale',
    ]),

    filteredOptions() {
      return (feature) => {
        const parent = this.surveyForm.find((q) => q.id === feature.parentId);

        if (!parent || !parent.answer) return feature.options;

        return feature.options.filter((o) => o.parentId === parent.answer.id);
      };
    },
  },

  methods: {
    ...mapActions(['fetchSurveyContent', 'pushAnswers']),

    ...mapMutations(['setToast', 'setStep', 'setAnswer', 'openSidebar']),

    previous() {
      this.setStep(this.currentStep - 1);
    },

    next() {
      this.setStep(this.currentStep + 1);
    },

    startLoading() {
      this.loading = true;
    },

    stopLoading() {
      this.loading = false;
    },

    async onSubmit() {
      if (this.currentStep < this.formLength - 1) {
        this.next();
        return;
      }

      const ok = await this.$refs.confirmDialogue.show({
        title: '¿Estás seguro?',
        message: '¿Estás seguro que deseas enviar la encuesta? Una vez enviada, no podrás cambiar tus respuestas.',
        isDestructive: false,
        okButton: 'Estoy Seguro, Enviar',
      });

      if (!ok) return;

      try {
        this.startLoading();

        await this.pushAnswers();

        this.setToast('Sus respuestas fueron enviadas correctamente');

        this.$router.push('/thank-you');
      } catch (error) {
        if (error.message === 'Form not completed') {
          this.setToast('Debe responder todas las preguntas para enviar la encuesta');
        } else this.setToast('Ocurrió un error, por favor inténtalo nuevamente');
      } finally {
        this.stopLoading();
      }
    },
  },

  async mounted() {
    try {
      this.startLoading();
      await this.fetchSurveyContent();
      this.stopLoading();
    } catch (error) {
      this.setToast('No se pudo cargar la información, por favor intentalo nuevamente');
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.company.activeSurveyId) return next('/no-survey');

      const endDate = new Date(`${vm.survey.endDate}T00:00:00`.replace(/-/g, '/').replace(/T.+/, '')).toISOString().slice(0, 10);

      const today = new Date().toISOString().slice(0, 10);

      if (endDate < today) return next('/no-survey');

      if (vm.finishedSurvey) return next('/thank-you');

      return next();
    });
  },
};
</script>

<style lang="scss" scoped>
.view {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%230054B4' fill-opacity='0.14'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  overflow-x: hidden;
}

.header {
  display: flex;
  align-items: center;
  grid-gap: var(--lengthMd2);
  gap: var(--lengthMd2);
}

.header .btn-container {
  margin-left: auto;
}

.survey-form {
  flex-grow: 1;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto auto;
  grid-gap: var(--lengthMd1);
  // justify-content: space-between;
  padding: var(--lengthMd1);

  @media only screen and (min-width: 768px) {
    padding: var(--lengthMd2);
  }

  @media only screen and (min-width: 1140px) {
    padding: var(--lengthMd3);
  }
}

.navigation {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "bar bar" "previous-btn next-btn";
  grid-gap: var(--lengthMd2);
  gap: var(--lengthMd2);
  align-items: center;
  padding: var(--lengthMd2);
  background-color: white;
  border-radius: var(--lengthSm1);
  box-shadow: 0 0 2px 1px rgba($color: #000000, $alpha: .1);

  .btn:first-child {
    grid-area: previous-btn;
  }

  .btn:last-child {
    grid-area: next-btn;
  }

  .bar {
    grid-area: bar;
  }

  @media only screen and (min-width: 980px) {
    grid-template-columns: auto 1fr auto;
    grid-template-areas: "previous-btn bar next-btn";
    padding: var(--lengthSm3);
  }
}

.question-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.question-enter {
  transform: translateX(2rem);
  opacity: 0;
}

.question-enter-active,
.question-leave-active {
  transition: transform 150ms linear, opacity 150ms linear;
}

.question-enter-to {
  transform: translateX(0);
}

.question-leave-to {
  transform: translateX(-2rem);
  opacity: 0;
}

.contact-us {
  display: flex;
  align-items: center;
  grid-gap: var(--lengthSm2);
  gap: var(--lengthSm2);
}

.contact-us span {
  font-weight: var(--medium);
}

.link {
  color: var(--mainColor1);
  font-weight: var(--medium);
}

.link:hover {
  text-decoration: underline;
}
</style>
